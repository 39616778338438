<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">数据中心</a>
                    <i>></i>
                    <a href="javascript:;">简历推送统计</a>
                    <i>></i>
                    <a href="javascript:;" @click="$router.back()">简历推送情况</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">查看沟通记录</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl">
                    <div class="searchbox">
                        <div title="企业名称" class="searchboxItem ci-full">
                            <span class="itemLabel">岗位名称：{{row.positionName}}</span>
                        </div>
                        <div title="推送时间" class="searchboxItem ci-full">
                            <span class="itemLabel">姓名：{{row.userName}}</span>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            :height="tableHeight"
                            size="small"
                            :data="listData"
                            tooltip-effect="dark"
                            :header-cell-style="tableHeader"
                            stripe
                            >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                                :index="indexMethod"
                                fixed
                            />
                            <el-table-column
                                label="内容"
                                align="center"
                                prop="remark"
                            />
                            <el-table-column
                                label="状态"
                                align="center"
                            >
                                <template v-slot="{row}">
                                    {{$setDictionary("HR_CONTACT_RESULT", row.contactResult)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="记录时间"
                                align="center"
                                prop="createTime"
                            />
                            <el-table-column
                                label="记录人"
                                align="center"
                                prop="createUser"
                            />
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum
                    :apiData="pagination"
                    @sizeChange="sizeChange"
                    @getData="currentChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import List from "@/mixins/List";
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    export default {
        name:"ResumeCommunicationRecord",
        mixins:[List],
        components:{
            Empty,
            PageNum
        },
        data(){
            return{
                // 分页器数据
                pagination:{
                    total:0,
                    size:10,
                    current:1
                },
                // 上一页面数据
                row:{},
                // 列表数据
                listData:[]
            }
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            // 初始化数据
            initData(){
                this.row=JSON.parse(this.$route.query.row);
                this.getComRec();
            },
            // 获取列表数据
            getComRec(){
                const {row,pagination}=this;
                this.$post("/hr/talent/rec-recruitment-communication-log/communicationLogPage",{
                    talentId:row.talentId,
                    recruitmentId:row.recruitmentId,
                    pageNum:pagination.current,
                    pageSize:pagination.size
                }).then(res=>{
                    this.listData=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 分页器每页条数改变
            sizeChange(size){
                const {pagination}=this;
                pagination.size=size;
                this.getComRec();
            },
            // 分页器当前页改变
            currentChange(current){
                const {pagination}=this;
                pagination.current=current;
                this.getComRec();
            },
            // 列表高度
            getTableHeight() {
                this.tableHeight=window.innerHeight-260;
            },
        },
        created(){
            this.initData();
        }
    }
</script>

<style scoped lang="less">

</style>